import ClayLayout from "@clayui/layout";
import { useEffect, useState } from "react";
import { getLearningPathsPaged } from "../services/menu";
import "../index.css";
import { convertMinutesToDuration } from "../utils/util";

const LearningPathsList = ({ paging, page, pageSize, durationFormat }) => {
  const [learningPaths, setLearningPaths] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getLearningPathsPaged(1, 3).then(async (learningPaths) => {
      const updatedLearningPaths = await Promise.all(
        learningPaths.map(async (learningPath) => {
          let personaList = learningPath.persona[0].name;
          if (learningPath.persona.length > 1) {
            personaList = `${personaList} + ${learningPath.persona.length - 1}`;
          }

          return {
            ...learningPath,
            duration: convertMinutesToDuration(
              learningPath.durationMinutes,
              "hours:minutes"
            ),
            personaList: personaList,
          };
        })
      );
      setLearningPaths(updatedLearningPaths);
      setIsLoading(false);
    });
  }, []);

  return (
    <ClayLayout.ContainerFluid view>
      <ClayLayout.Row justify="around">
        {learningPaths && learningPaths.length > 0 && (
          <>
            {learningPaths.map((learningPath, index) => {
              return (
                <ClayLayout.Col
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  className="d-flex course-layout-col"
                >
                  <a href={`/l/${learningPath.id}`} id="course-container-link">
                    <div className="course-container d-flex learn-education__landing-card">
                      <div className="course-content-card d-flex flex-column justify-content-between">
                        <h4 className="title">{learningPath.title}</h4>
                        <div className="description">
                          {learningPath.description}
                        </div>

                        <div
                          className="d-flex information learn-education"
                          key={learningPath.id}
                        >
                          <div className="card-tag card-tag__time">
                            {`${learningPath.duration} hours`}
                          </div>
                          <div className="card-tag card-tag__persona">
                            <p></p>
                            {learningPath.personaList}
                          </div>
                          <div
                            className={`card-tag card-tag__expertise-${learningPath.level.name.toLowerCase()}`}
                          >
                            <p>{learningPath.level.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </ClayLayout.Col>
              );
            })}
          </>
        )}
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
};

export default LearningPathsList;
