import ClayLayout from "@clayui/layout";
import { useEffect, useState } from "react";
import { getCoursesPaged } from "../services/menu";
import "../index.css";
import { convertMinutesToDuration } from "../utils/util";
import { enrollToCourse, getEnrollmentsByCourseId } from "../services/course";
import { isSignedIn } from "../utils/util";

const CoursesList = ({ paging, page, pageSize, durationFormat }) => {
  const [courses, setCourses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCoursesPaged(1, 3).then(async (courses) => {
      const updatedCourses = await Promise.all(
        courses.map(async (course) => {
          let personaList = course.audience[0].name;
          if (course.audience.length > 1) {
            personaList = `${personaList} + ${course.audience.length - 1}`;
          }

          const enrollmentData = await getEnrollmentsByCourseId(course.id);
          return {
            ...course,
            duration: convertMinutesToDuration(
              course.durationMinutes,
              "hours:minutes"
            ),
            personaList: personaList,
            firstLesson: "/l/" + (course.children[0]?.children[0]?.id || ""),
            isEnrolled: enrollmentData.items.length ? true : false,
          };
        })
      );
      setCourses(updatedCourses);
      setIsLoading(false);
    });
  }, []);

  return (
    <ClayLayout.ContainerFluid view>
      <ClayLayout.Row justify="around">
        {courses && courses.length > 0 && (
          <>
            {courses.map((course, index) => {
              return (
                <ClayLayout.Col
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  className="d-flex course-layout-col"
                >
                  <a href={course.firstLesson} id="course-container-link">
                    <div className="course-container d-flex learn-education__landing-card">
                      <div className="course-content-card d-flex flex-column justify-content-between">
                        <h4 className="title">{course.title}</h4>
                        <div className="description">{course.description}</div>
                        {isSignedIn && (
                          <>
                            {course.isEnrolled ? (
                              <button
                                className="btn btn-sm btn-secondary align-self-start opacity-0"
                                type="button"
                              >
                                Enrolled
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm btn-secondary align-self-start card-tag"
                                type="button"
                                onClick={() => enrollToCourse(course.id)}
                              >
                                Enroll
                              </button>
                            )}
                          </>
                        )}

                        <div
                          className="d-flex information learn-education"
                          key={course.id}
                        >
                          <div className="card-tag card-tag__time">
                            {`${course.duration} hours`}
                          </div>
                          <div className="card-tag card-tag__persona">
                            <p></p>
                            {course.personaList}
                          </div>
                          <div
                            className={`card-tag card-tag__expertise-${course.level.name.toLowerCase()}`}
                          >
                            <p>{course.level.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </ClayLayout.Col>
              );
            })}
          </>
        )}
      </ClayLayout.Row>
    </ClayLayout.ContainerFluid>
  );
};

export default CoursesList;
